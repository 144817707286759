<template>
  <div :style="{ width: `${width}px`, height: `${height}px` }">
    <canvas ref="canvas" class="canvas" />
  </div>
</template>

<script>
import QRCode from 'qrcode'

export default {
  name: 'VueQrcode',
  props: {
    value: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 200
    },
    height: {
      type: Number,
      default: 200
    }
  },
  watch: {
    value: {
      handler() {
        this.initQrCode()
      },
      immediate: true
    }
  },
  methods: {
    initQrCode() {
      this.$nextTick(() => {
        this.$refs.canvas.width = this.width
        this.$refs.canvas.height = this.height
        QRCode.toCanvas(this.$refs.canvas, this.value, {
          width: this.width,
          margin: 0,
          height: this.height
        })
      })
    }
  }
}
</script>

<style scoped>
.canvas {
  width: 100% !important;
  height: 100% !important;
}
</style>
